import React, {ReactNode, useEffect, useState} from 'react'
import {Avatar, Col, Row, Spin} from 'antd'
import {RightOutline} from 'antd-mobile-icons'
import DoughnutChart from '../component/DoughnutChart'
import {Card} from 'antd-mobile'
import ListAsset from '../component/ListAsset'
import {useNavigate} from 'react-router-dom'
import Logo from '../iconBoy/Logo_final-01 2.png'
import aiIcon from '../iconBoy/AI.svg'
import statIcon from '../iconBoy/stat.svg'
import contractUs from '../iconBoy/contractUs.svg'
import {axiosInvestor} from '../config/axiosConfig'
import {asset} from '../constants/interfaceAndType'
import consigneXLogo from '../iconBoy/logo.jpg'
import {liff} from '@line/liff'

interface SphereProps {
    name: string
    icon: ReactNode
    onClick?: () => void
}

const Home: React.FC = () => {
    const [listAsset, setListAsset] = useState<asset[]>([])
    const [loading, setLoading] = useState(false)
    const [iconSrc, setIconSrc] = useState<string>(consigneXLogo)
    const navigate = useNavigate()
    const phoneNumber = localStorage.getItem('phoneNumber')
    const [name, setName] = useState(phoneNumber)

    useEffect(() => {
        getListMyAsset()
        getUser()
    }, [])

    const getListMyAsset = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/asset/all')
            setListAsset(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    const getUser = async () => {
        if (process.env.REACT_APP_ENV === 'production' && liff.isLoggedIn()) {
            try {
                const profile = await liff.getProfile()
                setIconSrc(profile.pictureUrl || '')
                setName(profile.displayName)
            } catch (e) {
                // handle interceptor
            }
        }
    }

    return (
        <>
            <div className="p-4 pb-12 pt-0 bg-gradient-to-b from-blue-g-1 from-40% via-blue-300 ">
                <div className='flex justify-between items-center'>
                    <img src={Logo} alt='logo' width={75}/>
                    <div className="text-white">
                        {name}
                        <Avatar
                            className="bg-slate-500 ml-2"
                            src={iconSrc}
                        >
                        </Avatar>
                    </div>
                </div>
                <div className='mt-0 shadow-md rounded-2xl mb-5'>
                    <Card
                        onClick={() => navigate('/portfolio')}
                        title='My Portfolios'
                        extra={<RightOutline/>}
                    >
                        <DoughnutChart/>
                    </Card>
                </div>
                <Row justify='center'>
                    <Col span={6}>
                        <Sphere
                            name='Invest'
                            icon={
                                <img
                                    src={statIcon}
                                    width={28}
                                    alt='icon'
                                />
                            }
                            onClick={() => navigate('/invest')}
                        />
                    </Col>
                    <Col span={6}>
                        <Sphere
                            name='Asses AI'
                            icon={
                                <img
                                    src={aiIcon}
                                    width={34}
                                    alt='icon'
                                />
                            }
                            onClick={() => navigate('/ai')}
                        />
                    </Col>
                    <Col span={6}>
                        <Sphere
                            name='contact Us'
                            icon={
                                <img
                                    src={contractUs}
                                    width={34}
                                    alt='icon'
                                />
                            }
                            onClick={() => navigate('/more/contract')}
                        />
                    </Col>
                </Row>
            </div>
            <Spin spinning={loading}>
                <div className='relative bottom-6 mx-4 rounded-xl drop-shadow-xl shadow-black'>
                    <ListAsset
                        title='ทรัพย์ที่ทำธุรกรรม'
                        data={listAsset}
                        right
                        onClick={(item) => navigate(`/assets/${encodeURIComponent(item.id)}`)}
                    />
                </div>
            </Spin>
        </>
    )
}

const Sphere: React.FC<SphereProps> = ({name, icon, onClick}) => {
    return (
        <div
            className='grid justify-items-center text-center text-sm mx-2 '
            onClick={onClick}
        >
            <div
                className='bg-gradient-to-t from-blue-boy-icon2 to-blue-boy-icon flex justify-center w-14 h-14 p-1 rounded-full'>
                {icon}
            </div>
            {name}
        </div>
    )
}

export default Home

import {Spin, Tag, Typography} from 'antd'
import {List} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import {axiosInvestor} from '../../config/axiosConfig'

type auction = {
    id: string
    title: string
    businessType: string
    offer: number
}

const TabResultAuction: React.FC = () => {
    const [auction, setAuction] = useState<auction[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getAuction()
    }, [])

    const getAuction = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/auction/complete')
            setAuction(data)
        } catch (e) {
            // handle interceptor
            setAuction([
                {
                    id: '00124',
                    title: 'บางกอก บูเลอวาร์ด รังสิต คลอง 4',
                    businessType: 'ขายฝาก',
                    offer: 9,
                }
            ])
        }
        setLoading(false)
    }

    return (
        <div className='p-3'>
            <List>
                <Spin spinning={loading}>
                    <List.Item
                        title={
                            <Typography.Title level={4}>
                                รหัสการประมูล {auction[0]?.id || '-'}
                            </Typography.Title>
                        }
                        description={
                            <div>
                                ประเภทธุรกรรม : {auction[0]?.businessType || '-'}<br/>
                                <Tag color='success' className='mt-1'>ชนะการประมูล</Tag>
                            </div>
                        }
                        extra={
                            <Typography.Title level={4}>
                                {auction[0]?.offer || 0}%
                            </Typography.Title>
                        }
                    >
                        <div className='text-sm font-medium'>
                            {auction[0]?.title || '-'}
                        </div>
                    </List.Item>
                </Spin>
            </List>
        </div>
    )
}

export default TabResultAuction

import React from 'react'
import TabAsset from './TabAsset'
import TabContract from './TabContract'
import TabPay from './TabPay'
import TabBoy from '../../component/TabBoy'

const Asset: React.FC = () => {
    const options = [
        {
            label: 'หลักทรัพย์',
            value: 0
        },
        {
            label: 'สัญญา',
            value: 1
        },
        {
            label: 'ชำระดอกเบี้ย',
            value: 2
        },
    ]

    const tabs = [
        <TabAsset key={'TabAsset'}/>,
        <TabContract key={'TabContract'}/>,
        <TabPay key={'TabPay'}/>,
    ]

    return (
        <TabBoy title='ทรัพย์ที่ทำธุรกรรม' back tabs={tabs} options={options}/>
    )
}

export default Asset

import {Button, Col, Row, Spin, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import Layout2 from '../../component/Layout2'
import AssetComponent from '../../component/AssetComponent'
import {useNavigate, useParams} from 'react-router-dom'
import {mockData} from '../../constants/About'
import {axiosInvestor} from '../../config/axiosConfig'

type assetDetail = {
    title: string
    src: string[]
    estimatedAmount: number
    approvedAmount: number
    numberAsset: string
    transactionType: string
    businessType: string
    area: number
    status: string
    map: string
}

const InvestAsset: React.FC = () => {
    const [assetDetail, setAssetDetail] = useState<assetDetail>()
    const [loading, _setLoading] = useState(false)
    const navigate = useNavigate()
    const params = useParams()

    useEffect(() => {
        getAssetDetail()
    }, [])

    const getAssetDetail = async () => {
        try {
            const {data} = await axiosInvestor.get(`/invest/assetDetail/${params.asset}`)
            setAssetDetail(data)
        } catch (e) {
            // handle interceptor
            setAssetDetail({
                title: 'Origin Plug & Play Sirindhorn Station',
                src: mockData.map((item) => item.src),
                numberAsset: '652',
                businessType: 'บ้านเดี่ยว 2 ชั้น',
                estimatedAmount: 17000000,
                approvedAmount: 8500000,
                transactionType: 'ขายฝาก',
                area: 322,
                status: 'เปิดประมูล',
                map: 'map',
            })
        }
    }

    return (
        <Layout2 title='รายละเอียดทรัพย์' back>
            <Spin spinning={loading}>
                <AssetComponent
                    title={assetDetail?.title || '-'}
                    estimatedAmount={assetDetail?.estimatedAmount || 0}
                    approvedAmount={assetDetail?.approvedAmount || 0}
                    images={assetDetail?.src || []}
                    onclickImages={() => navigate(`/assets/${params.asset}/images`)}
                />
                <Typography.Title className='mt-4' level={5}>
                    รายระเอียดทรัพย์
                </Typography.Title>
                <Row className='p-3 pt-0'>
                    <Col span={12} className='text-gray-500'>
                        <div>หมายเลขทรัพย์</div>
                        <div>ประเภทธุรกรรม</div>
                        <div>ประเภททรัพย์</div>
                        <div>เนื้อที่</div>
                        <div>สถานะ</div>
                    </Col>
                    <Col className='text-right font-medium' span={12}>
                        <div>{assetDetail?.numberAsset || '-'}</div>
                        <div>{assetDetail?.transactionType || '-'}</div>
                        <div>{assetDetail?.businessType || '-'}</div>
                        <div>{assetDetail ? `${assetDetail.area} ตร.ม.` : '-'}</div>
                        <div className='text-green-500'>{assetDetail?.status || '-'}</div>
                    </Col>
                </Row>
                <Typography.Title className='mt-4' level={5}>
                    ตำแหน่งที่ตั้ง
                </Typography.Title>
                <iframe
                    className='border-2 border-black w-full'
                    title='map2'
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าพระนครเหนือ`}
                >
                </iframe>
                <Button
                    className='bg-blue-button text-white'
                    block
                    size='large'
                    onClick={() => navigate(`/auction/${encodeURIComponent(params.asset || '')}`)}
                >
                    ประมูลตอนนี้
                </Button>
            </Spin>
        </Layout2>
    )
}

export default InvestAsset

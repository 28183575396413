import React, {useEffect, useState} from 'react'
import {Badge, Button, Card, Col, Row, Spin} from 'antd'
import {useNavigate, useParams} from 'react-router-dom'
import {axiosInvestor} from '../../config/axiosConfig'
import {Divider, ProgressCircle, Tag} from 'antd-mobile'
import {formatDate} from '../../util/helper'

type paymentDetail = {
    contractId: string
    interestPaid: number
    interestRemaining: number
    interestAmount: number
    accruedInterest: number
    allRound: number
    round: number
    startDate: string
    dueDate: string
    nextPayment: number
    nextRound: number
}

const TabPay: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [paymentDetail, setPaymentDetail] = useState<paymentDetail>()
    const navigate = useNavigate()
    const param = useParams()

    useEffect(() => {
        getPaymentDetail()
    }, [])

    const getPaymentDetail = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/contract/paymentDetailInvestor', {
                params: {
                    assetId: decodeURIComponent(param.id || '')
                }
            })
            setPaymentDetail(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    return (
        <>
            <Spin spinning={loading} size="large" className="mt-[2em]">
                <Card>
                    <Row>
                        <Col span={12} className="text-[16px] font-bold">
                            เลขที่สัญญา
                        </Col>
                        <Col span={12} className="text-right">
                            <Tag
                                round
                                color="#B5D2FF"
                                style={{padding: '4px 8px', fontSize: '12px', fontWeight: 'bold', color: 'black'}}
                            >
                                {paymentDetail?.contractId || 'ไม่มีข้อมูล'}
                            </Tag>
                        </Col>
                    </Row>
                    <Row className="mt-[1em]">
                        <Col span={12} className="text-center">
                            <ProgressCircle
                                percent={paymentDetail?.interestPaid && paymentDetail.interestAmount ?
                                    Math.trunc(paymentDetail.interestPaid * 100 / paymentDetail.interestAmount) :
                                    0}
                                style={{
                                    '--size': '130px',
                                    '--track-width': '10px',
                                    '--fill-color': '#52C41A',
                                }}
                            >
                                <div className="font-bold text-[15px]">
                                    {paymentDetail?.interestPaid && paymentDetail.interestAmount ?
                                        Math.trunc(paymentDetail.interestPaid * 100 / paymentDetail.interestAmount) :
                                        0} %
                                </div>
                                <div style={{fontSize: '10px', color: 'var(--adm-color-weak)'}}>Complete</div>
                            </ProgressCircle>
                        </Col>
                        <Col span={10} offset={2}>
                            <Badge
                                color="#52C41A"
                                text={
                                    <span style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                        ดอกเบี้ยที่ชำระแล้ว
                                    </span>
                                }
                            />
                            <div className="font-bold ml-[13px]">
                                THB {paymentDetail?.interestPaid.toLocaleString() || 0}
                            </div>
                            <Badge
                                color="red"
                                text={
                                    <span style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                        ดอกเบี้ยคงค้าง
                                    </span>
                                }
                            />
                            <div className="font-bold ml-[13px]">
                                THB {paymentDetail?.accruedInterest.toLocaleString() || 0}
                            </div>
                            <Badge
                                color="#DCDCDC"
                                text={
                                    <span style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                        ดอกเบี้ยคงเหลือ
                                    </span>
                                }
                            />
                            <div className="font-bold ml-[13px]">
                                THB {paymentDetail?.interestRemaining.toLocaleString() || 0}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-[15px]">
                        <Col span={12}>
                            <span>ยอดชำระทั้งหมด</span>
                        </Col>
                        <Col span={12} className="text-right">
                            <span className="font-bold">
                                        THB{' '}
                                <span>
                                    {paymentDetail?.interestAmount
                                        ? Math.trunc((paymentDetail?.interestAmount * 100) / 100).toLocaleString()
                                        : 0}
                                </span>
                            </span>
                        </Col>
                    </Row>
                    <Divider className="my-[0.5em] mx-0"/>
                    <Row>
                        <Col span={12}>
                            <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>จำนวนงวดที่ชำระแล้ว</div>
                        </Col>
                        <Col span={12} className="text-right">
                            <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>วันที่เริ่มสัญญา</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} className="font-bold text-[14px]">
                            {paymentDetail?.round || 0} จาก {paymentDetail?.allRound || 0}
                        </Col>
                        <Col span={12} className="text-right font-bold text-[14px]">
                            {formatDate(paymentDetail?.startDate || '-')}
                        </Col>
                    </Row>
                </Card>
                <Row className="mt-[1em]">
                    <Col span={12} className="text-[16px] font-semibold">
                        <div className="text-[16px] font-semibold">การชำระที่กำลังมาถึง</div>
                    </Col>
                    <Col span={12} className="text-right">
                        <Tag
                            color="#DADADA"
                            round
                            style={{padding: '4px 8px', fontSize: '12px', fontWeight: 'bold', color: 'black'}}
                        >
                            {paymentDetail?.nextRound && paymentDetail?.nextRound !== paymentDetail?.allRound
                                ? paymentDetail?.nextRound : 0}/{paymentDetail?.allRound || 0}
                        </Tag>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="text-[16px] font-semibold">
                            ฿{paymentDetail?.nextPayment ? (Math.trunc(paymentDetail.nextPayment * 100) / 100).toLocaleString() : 0}
                        </div>
                    </Col>
                    <Col span={12} className="text-right">
                        <div style={{color: 'red'}}>ครบกำหนด {formatDate(paymentDetail?.dueDate || '-')}</div>
                    </Col>
                </Row>
                <Button
                    onClick={() => navigate(`/payment/${encodeURIComponent(paymentDetail?.contractId || '')}`)}
                    block
                    type='primary'
                    disabled={!paymentDetail}
                    size='large'
                    className='mt-24 bg-blue-button'
                >
                    ตรวจสอบการชำระ
                </Button>
            </Spin>
        </>
    )
}

export default TabPay

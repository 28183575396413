export const mockData = [
    {
        id: '112',
        title: 'Title 0',
        src: 'https://www.matichon.co.th/wp-content/uploads/2016/12/201612261742366-20021028190244.jpg',
        transactionType: 'ขายฝาก',
        estimatedAmount: 7000000,
        approvedAmount: 3500000,
    },
    {
        title: 'Title 1',
        id: '113',
        src: 'https://www.mitihoon.com/wp-content/uploads/2020/08/198-1_1.jpg',
        transactionType: 'ขายฝาก',
        estimatedAmount: 7000000,
        approvedAmount: 3500000,
    },
    {
        id: '44',
        title: 'Title 2',
        src: 'https://matemnews.com/wp-content/uploads/2020/02/1401080788-24235814-o.jpg',
        transactionType: 'ขายฝาก',
        estimatedAmount: 7000000,
        approvedAmount: 3500000,
    },
    {
        id: '112',
        title: 'Title 3',
        src: 'https://www.roojai.com/wp-content/uploads/2016/10/travel-hua-hin-around-palace.jpg',
        transactionType: 'ขายฝาก',
        estimatedAmount: 7000000,
        approvedAmount: 3500000,
    },
    {
        id: '912',
        title: 'Title 4',
        src: 'https://static.thairath.co.th/media/Dtbezn3nNUxytg04N1PHeXo6XQb07MBqs3AHiXjdgI3SmW.webp',
        transactionType: 'ขายฝาก',
        estimatedAmount: 7000000,
        approvedAmount: 3500000,
    },
    {
        id: '112',
        title: 'Title 5',
        src: 'https://upload.wikimedia.org/wikipedia/en/thumb/5/5f/Original_Doge_meme.jpg/300px-Original_Doge_meme.jpg',
        transactionType: 'ขายฝาก',
        estimatedAmount: 7000000,
        approvedAmount: 3500000,
    },
]

export const mockDataChart = [
    {
        name: 'Condo',
        amount: 6,
    },
    {
        name: 'Single-detached house',
        amount: 2,
    },
    {
        name: 'Town Home ',
        amount: 1,
    },
    {
        name: 'Up Country',
        amount: 1,
    },
]

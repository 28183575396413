import React from 'react'
import {createBrowserRouter, Outlet, redirect, RouterProvider} from 'react-router-dom'
import Layout from './component/Layout'
import Home from './app/Home'
import Asset from './app/asset/Asset'
import Documents from './app/document/Documents'
import Portfolio from './app/Portfolio'
import Payment from './app/payment/Payment'
import Images from './app/asset/Images'
import ListFile from './app/document/ListFile'
import File from './app/document/File'
import Invest from './app/invest/Invest'
import InvestAsset from './app/invest/InvestAsset'
import Auction from './app/auction/Auction'
import More from './app/more/More'
import SignUp from './app/more/SignUp'
import Contract from './app/more/Contract'
import Ai from './app/Ai'
import LayoutMore from './component/LayoutMore'
import LogIn from './app/LogIn'
import LogInOTP from './app/LogInOTP'
import LayoutLogInOTP from './app/LayoutLogInOTP'
import axiosInvestor from './config/axiosConfig'
import 'maplibre-gl/dist/maplibre-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dayjs = require('dayjs')
dayjs().format()
dayjs.locale('th')

const App: React.FC = () => {

    const router = createBrowserRouter([
        {
            path: 'logIn',
            element: <LogIn/>,
        },
        {
            path: 'logInOTP',
            element: <LayoutLogInOTP/>,
            children: [
                {
                    index: true,
                    element: <LogInOTP/>,
                },
            ]
        },
        {
            path: '/',
            element: <Layout content={<Outlet/>}/>,
            loader: async () => {
                const token = localStorage.getItem('investorToken')
                if (token) {
                    try {
                        const response = await axiosInvestor.get('/validateToken?token=' + token)
                        if (response.status === 200) {
                            return null
                        } else {
                            return redirect('/logIn')
                        }
                    } catch (error) {
                        return redirect('/logIn')
                    }
                }
                return redirect('/logIn')
            },
            errorElement: <h1>404 boy</h1>,
            children: [
                {
                    index: true,
                    element: <Home/>,
                },
                {
                    path: 'ai',
                    element: <Ai/>,
                },

                {
                    path: 'auction/:asset',
                    element: <Auction/>,
                },
                {
                    path: 'assets/:id',
                    element: <Asset/>,
                },
                {
                    path: 'assets/:id/images',
                    element: <Images/>,
                },
                {
                    path: 'assets/:contractId/documents',
                    element: <Documents/>,
                    children: [
                        {
                            path: ':document',
                            element: <ListFile/>
                        }
                    ]
                },
                {
                    path: 'assets/:contractId/documents/:document/:fileId',
                    element: <File/>,
                },
                {
                    path: 'invest',
                    element: <Invest/>,
                },
                {
                    path: 'invest/:asset',
                    element: <InvestAsset/>,
                },
                {
                    path: 'more',
                    element: <LayoutMore/>,
                    children: [
                        {
                            index: true,
                            element: <More/>,
                        },
                    ]
                },
                {
                    path: 'more/contract',
                    element: <Contract/>,
                },
                {
                    path: 'more/sign-up',
                    element: <SignUp/>,
                },
                {
                    path: 'payment/:contractId',
                    element: <Payment/>,
                },
                {
                    path: 'portfolio',
                    element: <Portfolio/>,
                },
            ],
        },
    ])

    return <RouterProvider router={router}/>
}

export default App

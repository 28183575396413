import React, {useEffect, useState} from 'react'
import {List} from 'antd-mobile'
import {useNavigate, useParams} from 'react-router-dom'
import {axiosInvestor} from '../../config/axiosConfig'
import {Empty, Skeleton} from 'antd'
import PdfIcon from '../../iconBoy/Pdf.svg'
import JpegImage from '../../iconBoy/JpegImage.svg'

type listFiles = {
    title: string
    fileId: string
}

const ListFile: React.FC = () => {
    const navigate = useNavigate()
    const [listFiles, setListFiles] = useState<listFiles[]>([])
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const contractId = localStorage.getItem('contractId')

    useEffect(() => {
        getListFile()
    }, [])

    const getListFile = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/contract/listFileContractInvestor', {
                params: {
                    contractId: contractId,
                    documentType: decodeURIComponent(params.document || ''),
                }
            })
            setListFiles(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    return (
        <Skeleton loading={loading} active>
            {listFiles.length !== 0 ? (
                <List style={{'--border-top': 'none', '--padding-left': '0', '--padding-right': '0'}}>
                    {listFiles.map((item: any, index: number) => (
                        <List.Item
                            key={index}
                            prefix={
                                <div
                                    className={`flex rounded-[6px] p-[8px] my-[8px] mx-[0px] ${item?.type === 'pdf' ? 'bg-[#FFE7E7]' : 'bg-[#C0E1F9]'}`}>
                                    {
                                        <img
                                            src={item?.type === 'pdf' ? PdfIcon : JpegImage}
                                            alt="icon"
                                            width={30}
                                            height={30}
                                        />
                                    }
                                </div>
                            }
                            onClick={() => navigate(encodeURIComponent(item.fileId))}
                        >
                            <div className="font-semibold text-[13px]">{item.title}</div>
                        </List.Item>
                    ))}
                </List>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="mt-[2em]"/>
            )}
        </Skeleton>
    )
}

export default ListFile

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import {liff} from '@line/liff'
import {datadogRum} from '@datadog/browser-rum'

if (process.env.REACT_APP_DATADOG_ENV) {
    datadogRum.init({
        applicationId: '2c36f4bd-f3e8-41e7-ac8f-36884e1e65f6',
        clientToken: 'pubd62b89aed25c3396c29a91b9f0ba460e',
        site: 'datadoghq.com',
        service: 'consignex-investor',
        env: process.env.REACT_APP_DATADOG_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0', 
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    })
}

const startApp = async () => {
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    )
    try {
        if (process.env.REACT_APP_ENV === 'production') {
            await liff.init({
                liffId: process.env.REACT_APP_LINE_LIFF_ID || ''
            })
        }
    } catch (e) {
        // alert(`LIFF error: ${(e as Error).message}`)
    }
    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
    )
}

startApp()

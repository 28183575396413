import React, {ReactNode} from 'react'
import {NavBar} from 'antd-mobile'
import {Typography} from 'antd'
import {useNavigate} from 'react-router-dom'

interface props {
    title: string
    children?: ReactNode
    right?: ReactNode
    back?: boolean
}

const Layout2: React.FC<props> = ({title, children, right, back = false}) => {
    const navigate = useNavigate()

    return (
        <div className='pt-3 bg-gradient-to-b from-blue-layout from-80% via-white via-80% '>
            <NavBar
                className='text-white'
                backArrow={back}
                onBack={back ? () => navigate(-1) : undefined}
                right={right}
            >
                <Typography.Title level={4}>
                    <span className='text-white'>{title}</span>
                </Typography.Title>
            </NavBar>
            <div className='bg-white rounded-t-3xl p-4 mt-2'>
                {children}
            </div>
        </div>
    )
}

export default Layout2

import axios from 'axios'
import {notification} from 'antd'

const handleErrorResponse = (error: {
    response: { status: number; data: { error: any; message: any } }
}) => {
    if (error.response && error.response.status !== 404 && error.response.status !== 401) {
        notification['error']({
            message: error.response.data.error || error.response.data.message || error.response.data,
            description: error.response.data.message,
            duration: 5
        })
    }
    if (process.env.REACT_APP_ENV === 'development') console.error('**interceptors error**', error.response)
}

export const axiosInvestor = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 35000,
})

axiosInvestor.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('investorToken')
        if (token) {
            config.headers['Authorization'] = token
        } else {
            config.headers['Authorization'] = 'No-Token'
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axiosInvestor.interceptors.response.use(
    (response) => response,
    async (error) => {
        await handleErrorResponse(error)
        return Promise.reject(error)
    }
)

export const axiosBackendBoy = axios.create({
    baseURL: 'http://localhost:8080',
    timeout: 1000,
})

export default axiosInvestor

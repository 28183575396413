import React, {useEffect, useState} from 'react'
import {ArcElement, Chart as ChartJS, Plugin} from 'chart.js'
import {Doughnut} from 'react-chartjs-2'
import {Badge, Col, Divider, Row, Spin} from 'antd'
import {axiosInvestor} from '../config/axiosConfig'
import {formatAssetType} from '../util/helper'
import money2Green from '../iconBoy/money2Green.svg'
import money2Red from '../iconBoy/money2Red.svg'
import bankMoney from '../iconBoy/bankMoney.svg'

ChartJS.register(ArcElement)

type dataDonut = {
    approvedAmount: number
    transferAmount: number
    accruedInterest: number
    asset: { name: string, amount: number }[]
}

const DoughnutChart: React.FC = () => {
    const [data, setData] = useState<dataDonut>()
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getDataChart()
    }, [])

    const getDataChart = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/asset')
            setData(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    const color = [
        '#0d12a1',
        '#3146F5',
        '#35CFFF',
        '#47D4DD',
        '#6d6fe3',
        '#9ea0f0',
        '#aeb0f5',
        '#002E94',
        '#02054f',
        '#02054d',
    ]

    const dataChart = {
        datasets: [
            {
                data: data?.asset?.length !== 0 ? data?.asset?.map((item) => Number(item.amount)) : [1],
                backgroundColor: data && data?.asset?.length > 0 ? color : '#E3E3E3',
                cutout: '70%',
            }
        ],
    }

    const textCenter: Plugin<'doughnut'> = {
        id: 'textCenter',
        afterDraw: (chart: { ctx: any; chartArea: { top: any; left: any; width: any; height: any }; data: any }) => {
            const {ctx, chartArea: {top, left, width, height}, data} = chart
            ctx.save()
            ctx.font = 'bold 0.875rem sans-serif'
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = '#000000'
            const centerX = left + width / 2
            const centerY = top + height / 2
            let sum = 0
            data.datasets?.forEach((dataset: { data: any[] }) => {
                dataset?.data?.forEach(value => {
                    if (typeof value === 'number') {
                        sum += value
                    }
                })
            })
            if (data.datasets[0]?.backgroundColor === '#E3E3E3') {
                sum = 0
            }
            ctx.fillText(`${sum}`, centerX, centerY - 8)
            ctx.fillText('Asset', centerX, centerY + 8)
            ctx.restore()
        }
    }

    return (
        <Spin spinning={loading}>
            <Row>
                <Col span={8} offset={2}>
                    <div className='w-5/6'>
                        <Doughnut data={dataChart} plugins={[textCenter]}/>
                    </div>
                </Col>
                <Col span={14}>
                    {
                        data?.asset.map((value, index) =>
                            <div
                                className='text-xs flex justify-between'
                                key={`div1-${index}`}
                                style={{
                                    fontSize: '14px'
                                }}
                            >
                                <Badge
                                    className='mr-2'
                                    color={color[index]}
                                    text={
                                        <span
                                            className='text-xs'
                                            style={{fontSize: '14px'}}
                                        >
                                            {formatAssetType(value.name)}
                                        </span>}
                                />
                                {value.amount} ยูนิต
                            </div>)
                    }
                    {data?.asset.length === 0 &&
                        <div className='text-xs mt-3'>คุณยังไม่มีทรัพย์สินที่ทำธุรกรรม</div>
                    }
                </Col>
            </Row>
            <Row style={{marginTop: '15px'}}>
                <Col span={12} style={{display: 'flex'}}>
                    <img
                        className='mx-1'
                        src={bankMoney}
                        width={12}
                        height={12}
                        alt='icon'
                    />
                    เงินลงทุนรวม
                </Col>
                <Col span={12} style={{textAlign: 'right', fontWeight: 'bold'}}>
                    THB {data?.approvedAmount.toLocaleString()}
                </Col>
            </Row>
            <Divider style={{margin: '1em 0'}}/>
            <div style={{backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px'}}>
                <Row>
                    <Col span={12} style={{display: 'flex'}}>
                        <img
                            className='mx-1'
                            src={money2Green}
                            width={12}
                            height={12}
                            alt='icon'
                        />
                        ดอกเบี้ยที่ได้รับแล้ว
                    </Col>
                    <Col span={12} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        THB {data?.transferAmount.toLocaleString()}
                    </Col>
                </Row>
                <Row>
                    <Col span={12} style={{display: 'flex'}}>
                        <img
                            className='mx-1'
                            src={money2Red}
                            width={12}
                            height={12}
                            alt='icon'
                        />
                        ดอกเบี้ยค้างรับ
                    </Col>
                    <Col span={12} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        THB {data?.accruedInterest.toLocaleString()}
                    </Col>
                </Row>
            </div>
        </Spin>
    )
}

export default DoughnutChart

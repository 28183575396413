import React, { useEffect, useState } from 'react'
import { Col, Divider, Row, Spin, Tag, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { axiosInvestor } from '../../config/axiosConfig'
import ListDocuments from '../document/ListDocuments'
import {
    formatBackgroundColorContractStatus,
    formatColorContractStatus,
    formatContractStatus,
    formatDate
} from '../../util/helper'

type ContractDetail = {
    contractId: string;
    debtor: string;
    investor: string;
    date: string;
    dueDate: string;
    certificate: string;
    status: string;
    businessType: string;
    period: number;
    interestPerMonth: number;
    transferCost: number;
    bagOpenFee: number;
    advanceInterest: number;
    estimatedAmount: number;
    amountBusiness: number;
};

const TabContract: React.FC = () => {
    const [contractDetail, setContractDetail] = useState<ContractDetail>()
    const [loading, setLoading] = useState(false)
    const params = useParams<{ id: string }>()

    useEffect(() => {
        getContractDetail()
    }, [])

    const getContractDetail = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInvestor.get('/contract/detail', {
                params: {
                    id: decodeURIComponent(params.id || ''),
                },
            })
            setContractDetail(data)
            localStorage.setItem('contractId', data.contractId)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    const detailRows = [
        { label: 'เลขที่สัญญา', value: contractDetail?.contractId || 'ไม่มีเลขสัญญา' },
        { label: 'ชื่อผู้ทำธุรกรรม', value: contractDetail?.debtor || '-' },
        { label: 'ชื่อผู้รับ', value: contractDetail?.investor || '-' },
        { label: 'วันที่ทำสัญญา', value: formatDate(contractDetail?.date || '-') },
        { label: 'ครบกำหนดสัญญา', value: formatDate(contractDetail?.dueDate || '-') },
        { label: 'เอกสารสิทธิ', value: contractDetail?.certificate || '-' },
        { label: 'สถานะ', value: contractDetail?.status ?
            <Tag
                style={{
                    fontSize: 12,
                    padding: '4px',
                    color: formatColorContractStatus(contractDetail?.status),
                    borderColor: formatColorContractStatus(contractDetail?.status),
                    backgroundColor: formatBackgroundColorContractStatus(contractDetail?.status),
                }}
            >
                {formatContractStatus(contractDetail?.status)}
            </Tag> : '-' },
        { label: 'ประเภท', value: contractDetail?.businessType || '-' },
        { label: 'ระยะเวลา', value: contractDetail?.period ? `${contractDetail.period} เดือน` : '-' },
        { label: 'ดอกเบี้ยต่อเดือน', value: contractDetail?.interestPerMonth ? `${contractDetail.interestPerMonth} %` : '-' },
        { label: 'ค่าใช้จ่ายในการโอน', value: contractDetail?.transferCost ? `THB ${contractDetail.transferCost.toLocaleString()}` : '-' },
        { label: 'ค่าปากถุง', value: contractDetail?.bagOpenFee ? `THB ${contractDetail.bagOpenFee.toLocaleString()}` : '-' },
        { label: 'ดอกเบี้ยล่วงหน้า', value: contractDetail?.advanceInterest ? `${contractDetail.advanceInterest} เดือน` : '-' }
    ]

    return (
        <div style={{ margin: '5px' }}>
            <Spin spinning={loading} size="large" style={{ marginTop: '2em' }}>
                <Typography.Title level={4} style={{ margin: 0, fontWeight: 700 }}>รายละเอียดสัญญา</Typography.Title>
                <Row style={{ marginTop: '15px' }}>
                    {detailRows.map(({ label, value }, index) => (
                        <React.Fragment key={index}>
                            <Col span={8}>{label}</Col>
                            <Col span={16} style={{ textAlign: 'right', fontWeight: 'bold' }}>{value}</Col>
                            {index < detailRows.length - 1 && <Divider style={{ margin: '1em 0' }} />}
                        </React.Fragment>
                    ))}
                </Row>
                <Divider style={{ margin: '1em 0' }} />
                <div style={{ marginBottom: '1em' }}>
                    <span style={{ fontWeight: 600 }}>ราคาประเมิน :</span>
                    <div
                        className="contentCenter"
                        style={{
                            color: '#154A98',
                            fontWeight: 600,
                            border: '2px solid #154A98',
                            padding: '6px',
                            borderRadius: '5px',
                            fontSize: '17px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {contractDetail?.estimatedAmount.toLocaleString() ? 'THB ' + contractDetail?.estimatedAmount.toLocaleString() : '-'}
                    </div>
                </div>
                <div>
                    <span style={{ fontWeight: 600 }}>จำนวนเงินขายฝาก / จำนอง :</span>
                    <div
                        style={{
                            color: '#EE1723',
                            fontWeight: 600,
                            border: '2px solid #EE1723',
                            padding: '6px',
                            borderRadius: '5px',
                            fontSize: '17px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {contractDetail?.amountBusiness.toLocaleString() ? 'THB ' + contractDetail?.amountBusiness.toLocaleString() : '-'}
                    </div>
                </div>
                <Typography.Title level={4} className='mt-5'>
                        เอกสารสิทธิ์และอื่นๆ
                </Typography.Title>
                <ListDocuments />
            </Spin>
        </div>
    )
}

export default TabContract

import React, {ReactNode, useEffect, useState} from 'react'
import {Col, Modal, Row, Typography} from 'antd'
import contractIcon from '../../iconBoy/contract.svg'
import {EyeOutlined} from '@ant-design/icons'
import {Image, ImageViewer} from 'antd-mobile'
import {paymentDetail} from '../../constants/interfaceAndType'
import {formatDate, formatTime} from '../../util/helper'
import axiosInvestor from '../../config/axiosConfig'


interface props {
    isModalOpen: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    footer: boolean
    children: ReactNode
    dataPayment?: paymentDetail
}

interface bank {
    bankName: string,
    logo: string,
}

const ModalInfoPayment: React.FC<props> = ({isModalOpen, setIsModalOpen, footer, children, dataPayment}) => {
    const [bank, setBank] = useState<bank[]>([])

    useEffect(() => {
        fetchBankLogo()
    }, [dataPayment])

    const fetchBankLogo = async () => {
        try {
            const {data} = await axiosInvestor.get('/contract/getBankImage', {
                params: {
                    bank: dataPayment?.bank
                }
            })
            setBank(data)
        } catch (error) {
            // handle in interceptor
        }
    }

    return (
        <Modal
            title={
                <div className='text-lg'>
                    รายละเอียดหลักฐานการชำระเงิน
                </div>
            }
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
        >
            <Typography.Title
                className='border border-gray-300 p-2 rounded-lg m-3'
                level={5}
            >
                สัญญาขายฝาก : {dataPayment?.contractId || '-'}
            </Typography.Title>
            <div className='flex justify-center my-7'>
                <img src={contractIcon} alt='icon'/>
            </div>
            <Row className='p-3 pt-0'>
                <Col span={12} className='text-gray-500'>
                    <div>งวดที่ชำระดอกเบี้ย</div>
                    <div>ยอดเงินที่โอน</div>
                    <div>วันที่โอนเงิน</div>
                    <div>เวลาที่โอนเงิน</div>
                </Col>
                <Col className='text-right font-medium' span={12}>
                    <div>{`${dataPayment?.round}/${dataPayment?.allRound} ${dataPayment?.month} ${dataPayment?.year}` || '-'}</div>
                    <div>{dataPayment?.pay ? `${dataPayment.pay.toLocaleString()} บาท` : '-'}</div>
                    <div>{formatDate(dataPayment?.datePay || '-')}</div>
                    <div>{formatTime(dataPayment?.datePay || '-')}</div>
                </Col>
            </Row>
            <div className='p-2 px-3 rounded-xl flex justify-between border border-gray-300'>
                <div className='text-xs text-gray-400'>
                    <div className='text-sm font-medium text-black'>{dataPayment?.bank || '-'}</div>
                    <div>ชื่อบัญชี : {dataPayment?.bankAccount || '-'}</div>
                    <div>เลขที่บัญชี : {dataPayment?.bankId || '-'}</div>
                </div>
                <img src={bank[0]?.logo} alt='icon' width={'50px'}/>
            </div>
            {dataPayment?.imageSrc &&
                <div className='mt-4 flex justify-between p-2 px-3 border border-gray-300 rounded-xl items-center'>
                    <div className='flex items-center mr-2'>
                        <Image
                            src={dataPayment?.imageSrc}
                            width={41}
                            height={40}
                            alt='img' fit='cover'
                        />
                        <div className='ml-2'>
                            {dataPayment?.imageName}
                        </div>
                    </div>
                    <EyeOutlined
                        onClick={() => ImageViewer.show({
                            image: dataPayment?.imageSrc
                        })}
                        className='text-lg'
                    />
                </div>
            }
            {footer && children}
        </Modal>
    )
}

export default ModalInfoPayment

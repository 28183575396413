import {MailTwoTone, PhoneTwoTone} from '@ant-design/icons'
import {List} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import Layout2 from '../../component/Layout2'
import {Tag} from 'antd'
import {axiosInvestor} from '../../config/axiosConfig'

type contract = {
    email: string
    phoneNumber: string
}

const Contract: React.FC = () => {
    const [contract, setContract] = useState<contract>()

    useEffect(() => {
        getContract()
    }, [])

    const getContract = async () => {
        try {
            const {data} = await axiosInvestor.get('/contract')
            setContract(data)
        } catch (e) {
            // handle interceptor
        }
    }

    const handlePhoneCall = () => {
        if (contract) {
            window.location.href = `tel:${contract.phoneNumber}`
        }
    }

    const handleEmail = () => {
        if (contract) {
            window.location.href = `mailto:${contract.email}`
        }
    }

    return (
        <Layout2 title='ติดต่อเรา' back>
            <List>
                <List.Item
                    className='font-medium'
                    prefix={<MailTwoTone className='text-2xl'/>}
                    description={contract?.email || '-'}
                    arrow={false}
                    extra={<Tag color='blue' onClick={handleEmail}>ส่งอีเมล</Tag>}
                >
                    Email
                </List.Item>
                <List.Item
                    className='font-medium'
                    prefix={<PhoneTwoTone className='text-2xl'/>}
                    description={contract?.phoneNumber || '-'}
                    arrow={false}
                    extra={<Tag color='blue' onClick={handlePhoneCall}>โทรออก</Tag>}
                >
                    Phone
                </List.Item>
            </List>
        </Layout2>
    )
}

export default Contract

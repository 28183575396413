import React, {useEffect, useState} from 'react'
import Layout2 from '../component/Layout2'
import {Spin, Typography} from 'antd'
import DoughnutChart from '../component/DoughnutChart'
import ListAsset from '../component/ListAsset'
import {useNavigate} from 'react-router-dom'
import {asset} from '../constants/interfaceAndType'
import {axiosInvestor} from '../config/axiosConfig'


const Portfolio: React.FC = () => {
    const [listAsset, setListAsset] = useState<asset[]>([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        getListMyAsset()
    }, [])

    const getListMyAsset = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/asset/all')
            setListAsset(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    return (
        <Layout2 title='Portfolios' back>
            <Typography.Title level={4}>My Portfolio</Typography.Title>
            <div className='p-3 pl-0 pr-5'>
                <DoughnutChart/>
            </div>
            <Spin spinning={loading}>
                <ListAsset
                    title='ทรัพย์ที่ทำธุรกรรม'
                    data={listAsset}
                    onClick={(item) => navigate(`/assets/${encodeURIComponent(item.id)}`)}
                />
            </Spin>
        </Layout2>
    )
}

export default Portfolio

import React, {useState} from 'react'
import Layout2 from '../../component/Layout2'
import {Button, Form, Input, Select, Space, Spin} from 'antd'
import {Checkbox, Modal as ModalMobile} from 'antd-mobile'
import {CheckCircleFill} from 'antd-mobile-icons'
import {useNavigate} from 'react-router-dom'
import {axiosInvestor} from '../../config/axiosConfig'
import {
    investorInterestLocationOptionNoContract,
    investorInvestAmountOptionNoContract,
    investorRefferalOptionNoContract
} from '../../util/helper'

type FieldType = {
    name?: string
    lastname?: string
    phoneNumber?: string
    email?: string
    asset?: string[]
    area?: string
    rangeValue?: string
    time?: string
    referral?: string
    note?: string
};

const SignUp: React.FC = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const handleFinish = async (values: FieldType) => {
        setLoading(true)
        try {
            await axiosInvestor.post('/register', {
                firstName: values.name,
                lastName: values.lastname,
                phoneNumber: values.phoneNumber,
                email: values.email,
                assetInterest: values.asset,
                areaInterest: values.area,
                rangeValue: values.rangeValue,
                contactTime: values.time,
                referral: values.referral,
                note: values.note
            })
            showModal()
            form.resetFields()
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    const showModal = () => {
        ModalMobile.show({
            header: <CheckCircleFill className='text-7xl' color='#0DC75A'/>,
            title: 'อนุมัติเรียบร้อยแล้ว',
            content:
                <div className='p-3 px-12 text-blue-500'>
                    <Button
                        className='text-blue-500 border-blue-500'
                        block
                        onClick={() => {
                            ModalMobile.clear()
                            navigate('/portfolio')
                        }}
                    >
                        กลับสู่เมนูหลัก
                    </Button>
                </div>
            ,
            closeOnMaskClick: true,
        })
    }

    return (
        <Layout2 title='ลงทะเบียนเป็นนักลงทุน' back>
            <Spin spinning={loading}>
                <Form
                    className='mx-2'
                    form={form}
                    labelCol={{span: 24}}
                    onFinish={handleFinish}
                >
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>ชื่อ</span>}
                        name="name"
                        rules={[{required: true, message: 'กรุณากรอกชื่อของคุณ'}]}
                    >
                        <Input placeholder='กรอกชื่อ'/>
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>นามสกุล</span>}
                        name="lastname"
                        rules={[{required: true, message: 'กรุณากรอกนามสกุลของคุณ'}]}
                    >
                        <Input placeholder='กรอกนามสกุล'/>
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>เบอร์โทรศัพท์</span>}
                        name="phoneNumber"
                        rules={[{required: true, message: 'กรุณากรอกเบอร์โทรศัพท์ของคุณ'}]}
                    >
                        <Input
                            placeholder='กรอกเบอร์โทรศัพท์'
                            maxLength={10}
                            minLength={10}
                        />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>อีเมล</span>}
                        name="email"
                        rules={[{required: true, message: 'กรุณากรอกอีเมลของคุณให้ถูกต้อง', type: 'email'}]}
                    >
                        <Input placeholder='กรอกอีเมล'/>
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>ทรัพย์ที่สนใจ (เลือกได้มากกว่า 1 ข้อ)</span>}
                        name="asset"
                        rules={[{required: true, message: 'กรุณากรอกเลือกทรัพย์ที่สนใจ'}]}
                    >
                        <Checkbox.Group>
                            <Space direction='vertical'>
                                <Checkbox value='ที่ดิน'>ที่ดิน</Checkbox>
                                <Checkbox value='คอนโด'>คอนโด</Checkbox>
                                <Checkbox value='อาคารพาณิชย์'>อาคารพาณิชย์</Checkbox>
                                <Checkbox value='บ้านเดี่ยว'>บ้านเดี่ยว</Checkbox>
                                <Checkbox value='ทาวน์เฮ้าส์'>ทาวน์เฮ้าส์</Checkbox>
                            </Space>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>พื้นที่สนใจการลงทุน</span>}
                        name="area"
                        rules={[{required: true, message: 'กรุณาเลือกพื้นที่สนใจการลงทุน'}]}
                    >
                        <Select
                            className='formSelectDropdown'
                            placeholder={'กรุณาเลือก'}
                            options={investorInterestLocationOptionNoContract()}
                        />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>มูลค่าการลงทุน</span>}
                        name="rangeValue"
                        rules={[{required: true, message: 'กรุณาเลือกมูลค่าการลงทุน'}]}
                    >
                        <Select
                            className='formSelectDropdown'
                            placeholder={'กรุณาเลือก'}
                            options={investorInvestAmountOptionNoContract()}
                        />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>ช่วงเวลาที่สะดวกให้ติดต่อกลับ</span>}
                        name="time"
                        rules={[{required: true, message: 'กรุณากรอกเวลา'}]}
                    >
                        <Input placeholder='กรอกเวลา'/>
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>รู้จักโค้ชเต๊ะผ่านช่องทางใด (Referral)</span>}
                        name="referral"
                    >
                        <Select
                            className='formSelectDropdown'
                            placeholder={'กรุณาเลือก'}
                            options={investorRefferalOptionNoContract()}
                        />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label={<span className='text-lg font-medium'>เพิ่มเติม</span>}
                        name="note"
                    >
                        <Input.TextArea placeholder='กรอกข้อความ' rows={4}/>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className='text-white bg-blue-button'
                            htmlType="submit"
                            size='large'
                            block
                        >
                            ลงทะเบียน
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Layout2>
    )
}

export default SignUp
import React, {useEffect, useRef, useState} from 'react'
import {Col, Divider, Row, Spin, Typography} from 'antd'
import AssetComponent from '../../component/AssetComponent'
import {axiosInvestor} from '../../config/axiosConfig'
import {useNavigate, useParams} from 'react-router-dom'
import {formatAssetType} from '../../util/helper'
import PreviewMap from '../../component/Map/PreviewMap'
import Location from '../../iconBoy/Location.svg'
// @ts-ignore
import {MapRef} from 'react-map-gl'

const TabAsset: React.FC = () => {
    const [assetData, setAssetData] = useState<any>()
    const [assetImages, setAssetImages] = useState<any[]>()
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const navigate = useNavigate()
    const mapRef = useRef<MapRef | null>(null)
    const [geoJson, setGeoJson] = useState({
        'type': 'FeatureCollection',
        'features': {}
    })

    useEffect(() => {
        getAssetDetail()
        getAssetDetailImages()
    }, [])

    const getAssetDetail = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/asset/detail',
                {
                    params: {
                        id: decodeURIComponent(params.id || '')
                    }
                }
            )
            setAssetData(data)
            const newGeoJson = {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [data.contractDetail.assetDetail.longitude, data.contractDetail.assetDetail.latitude],
                        }
                    }
                ]
            }
            setGeoJson(newGeoJson)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    const getAssetDetailImages = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/asset/detail/image',
                {
                    params: {
                        id: decodeURIComponent(params.id || '')
                    }
                }
            )
            setAssetImages(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    return (
        <>
            <Spin spinning={loading}>
                <AssetComponent
                    title={assetData?.contractDetail?.assetDetail?.name || '-'}
                    estimatedAmount={assetData?.contractDetail?.estimatedAmount || 0}
                    approvedAmount={assetData?.contractDetail?.approvedAmount || 0}
                    images={assetImages || []}
                    onclickImages={() => navigate(`/assets/${assetData?.contractDetail?.assetDetail?.assetId}/images`)}
                />
                <Divider className="my-[1em] mx-0"/>
                <Typography.Title className="mt-[0.75em]" level={5}>
                    รายระเอียดทรัพย์
                </Typography.Title>
                <Row className="py-0 px-[1em]">
                    <Col span={8} className="text-[15px] font-medium leading-7">
                        <div>หมายเลขทรัพย์</div>
                        <div>ประเภทธุรกรรม</div>
                        <div>ประเภททรัพย์</div>
                        <div>เนื้อที่</div>
                        <div>ราคาประเมิน</div>
                        <div>อนุมัติราคา</div>
                    </Col>
                    <Col span={16} className="text-right text-[15px] font-semibold leading-7">
                        <div>{assetData?.contractDetail?.assetDetail?.assetId}</div>
                        <div>{assetData?.contractDetail?.transactionType || '-'}</div>
                        <div>{formatAssetType(assetData?.contractDetail?.assetDetail?.type) || '-'}</div>
                        <div>
                            {assetData?.contractDetail?.assetDetail?.areaRai + ' ' + 'ไร่' + ' '}
                            {assetData?.contractDetail?.assetDetail?.areaNgan + ' ' + 'งาน' + ' '}
                            {assetData?.contractDetail?.assetDetail?.areaTaRangWa + ' ' + 'ตร.ว.' + ' '}
                            {assetData?.contractDetail?.assetDetail?.areaSquareMeter + ' ' + 'ตร.ม.'}
                        </div>
                        <div>฿ {assetData?.contractDetail?.estimatedAmount.toLocaleString() || '-'}</div>
                        <div>฿ {assetData?.contractDetail?.approvedAmount.toLocaleString() || '-'}</div>
                    </Col>
                </Row>
                <Divider className="my-[1em] mx-0"/>
                <Typography.Title level={5} className="mt-[0.75em]">
                    ตำแหน่งที่ตั้ง
                </Typography.Title>
                <div className="flex mb-[30px]">
                    <img src={Location} alt="location" height={20} width={20}/>
                    <span style={{fontSize: 14, fontWeight: 500, marginLeft: '10px'}}>
                        {assetData?.contractDetail?.assetDetail?.address &&
                        assetData?.contractDetail?.assetDetail?.subDistrict &&
                        assetData?.contractDetail?.assetDetail?.district &&
                        assetData?.contractDetail?.assetDetail?.province ?
                            assetData?.contractDetail?.assetDetail?.address +
                            ' แขวง' + assetData?.contractDetail?.assetDetail?.subDistrict +
                            ' เขต' + assetData?.contractDetail?.assetDetail?.district +
                            ' ' + assetData?.contractDetail?.assetDetail?.province
                            : '-'
                        }
                    </span>
                </div>
                {assetData?.contractDetail?.assetDetail?.googleMapsLink &&
                    <PreviewMap
                        mapRef={mapRef}
                        geoJson={geoJson}
                    />
                }
            </Spin>
        </>
    )
}

export default TabAsset

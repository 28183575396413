import React from 'react'
import {Image, Swiper} from 'antd-mobile'
import {Col, Row, Statistic, Typography} from 'antd'
import moneyIcon from '../iconBoy/moneyGray.svg'
import moneyIcon2 from '../iconBoy/money2Gray.svg'

interface props {
    title: string
    estimatedAmount: number
    approvedAmount: number
    images: string[]
    onclickImages: () => void
}

const AssetComponent: React.FC<props> = ({title, estimatedAmount, approvedAmount, images, onclickImages}) => {

    return (
        <>
            {images.length > 0 &&
                <Swiper autoplay>
                    {images.map((item, index) =>
                        <Swiper.Item
                            key={`swiper-item-${index}`}>
                            <Image
                                onClick={onclickImages}
                                className='rounded-2xl max-w-xl'
                                height={256}
                                src={item}
                                fit='fill'
                            />
                        </Swiper.Item>
                    )}
                </Swiper>
            }
            <Typography.Title
                className='mt-[0.75em]'
                level={4}
            >
                {title}
            </Typography.Title>
            <Row>
                <Col
                    className='border-r-2 border-gray-200'
                    span={11}
                    offset={1}
                >
                    <Statistic
                        title={
                            <div className='flex items-center text-center text-gray-500'>
                                <img
                                    className='mx-1 fill-amber-100'
                                    src={moneyIcon}
                                    width={14}
                                    height={14}
                                    alt='icon'
                                />
                                มูลค่าหลักทรัพย์
                            </div>
                        }
                        value={estimatedAmount}
                        valueStyle={{color: '#0D3C8E', fontWeight: 600, fontSize: 20}}
                        prefix='฿'
                    />
                </Col>
                <Col span={11} offset={1}>
                    <Statistic
                        title={
                            <div className='flex items-center text-center text-gray-500'>
                                <img
                                    className='mx-1 fill-amber-100'
                                    src={moneyIcon2}
                                    width={14}
                                    height={14}
                                    alt='icon'
                                />
                                อนุมัติราคา
                            </div>
                        }
                        value={approvedAmount}
                        valueStyle={{color: '#FF4D4F', fontWeight: 600, fontSize: 20}}
                        prefix='฿'
                    />
                </Col>
            </Row>
        </>
    )
}

export default AssetComponent

import React, {useEffect, useState} from 'react'
import {List} from 'antd-mobile'
import {Spin, Tag} from 'antd'
import {useParams} from 'react-router-dom'
import {axiosInvestor} from '../../config/axiosConfig'
import {listPay} from '../../constants/interfaceAndType'
import {formatDate} from '../../util/helper'


const TabWaitForPay: React.FC = () => {
    const [listWaitForPay, setListWaitForPay] = useState<listPay[]>([])
    const [loading, setLoading] = useState(false)
    const params = useParams()

    useEffect(() => {
        getListWaitForPay()
    }, [])

    const getListWaitForPay = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/contract/paymentDetailInvestor/status', {
                params: {
                    contractId: decodeURIComponent(params.contractId || ''),
                    status: 'UnPaid'
                }
            })
            setListWaitForPay(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    return (
        <div className='m-3'>
            <Spin spinning={loading}>
                <List>
                    {listWaitForPay.map((item, index) =>
                        <List.Item
                            key={`listItem-${index}`}
                            description={`งวดที่ ${item.round} | ครบกำหนด ${formatDate(item.dueDate)}`}
                            extra={<span className='font-medium text-black'>THB {item.pay.toLocaleString()}</span>}
                        >
                            <div key={`span-${index}`} className='text-sm font-medium mb-2'>
                                {item.title} <br/>
                                <Tag color='red'>ยังไม่ได้ชำระ</Tag>
                            </div>
                        </List.Item>
                    )}
                </List>
            </Spin>
        </div>
    )
}

export default TabWaitForPay

import React, {useEffect, useState} from 'react'
import {Button, Form, Input, Statistic} from 'antd'
import {ResultPage} from 'antd-mobile'
import {axiosInvestor} from '../config/axiosConfig'
import {useNavigate} from 'react-router-dom'
import dayjs from 'dayjs'

const {Countdown} = Statistic

const LogInOTP: React.FC = () => {
    const navigate = useNavigate()
    const [disableOTP, setDisableOTP] = useState(true)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [deadLineTime, setDeadLineTime] = useState(0)
    const [requestOtpAgain, setRequestOtpAgain] = useState(false)
    const [form] = Form.useForm()
    const [showTime, setShowTime] = useState(false)
    const [otpValue, setOtpValue] = useState<string>('')

    useEffect(() => {
        if (phoneNumber.length === 10) {
            setDisableOTP(false)
        } else {
            setDisableOTP(true)
        }
    }, [phoneNumber])

    const sendRequestOTP = async () => {
        setRequestOtpAgain(false)
        try {
            const {data} = await axiosInvestor.post('/sentUserOtp', null, {
                params: {phoneNumber: phoneNumber}
            })
            const dateNow = dayjs()
            const expireTime = dayjs(data.expireTime)
            setDeadLineTime(dayjs().valueOf() + expireTime.diff(dateNow, 'millisecond'))
            setShowTime(true)
        } catch (e) {
            // handle interceptor
        }
    }

    const onFinish = async (values: any) => {
        try {
            const {data} = await axiosInvestor.post('/login', {
                phoneNumber: values.phoneNumber,
                otp: values.OTP
            })
            localStorage.setItem('investorToken', data.token)
            localStorage.setItem('phoneNumber', form.getFieldValue('phoneNumber'))
            navigate('/')
        } catch (e) {
            // handle interceptor
        }
    }

    return (
        <>
            <ResultPage.Card>
                <Form
                    form={form}
                    layout={'vertical'}
                    onFinish={(values) => onFinish(values)}
                >
                    <div className='shadow-md rounded-2xl p-4 pb-2'>
                        <div className="text-lg font-bold mb-1">กรอกข้อมูลเพื่อยืนยันตัวตน</div>
                        <div className="text-sm mb-5">*รองรับการใช้งานสำหรับบุคคลธรรมดาเท่านั้น</div>
                        <div className="text-md font-semibold mb-1">ระบุหมายเลขโทรศัพท์</div>
                        <div className='flex'>
                            <Form.Item
                                name="phoneNumber"
                                rules={[{required: false, message: 'กรุณากรอกหมายเลขโทรศัพท์'}]}
                                className="flex-auto w-64 mr-2"
                            >
                                <Input
                                    placeholder='000 000 0000'
                                    onChange={e => setPhoneNumber(e.target.value)}
                                />
                            </Form.Item>
                            <Button
                                className='bg-blue-button text-white ml-auto font-semibold flex-auto w-32'
                                size='middle'
                                onClick={sendRequestOTP}
                                disabled={disableOTP || showTime}
                            >
                                ขอรหัส OTP
                            </Button>
                        </div>
                        <div className="text-md font-semibold mb-1">ระบุ OTP ที่ได้รับทาง SMS</div>
                        <Form.Item
                            name="OTP"
                            rules={[
                                {required: true, message: 'กรุณากรอกรหัส OTP'},
                                {pattern: /^\d{6}$/, message: 'กรุณากรอกให้ครบ 6 หลัก'}
                            ]}
                        >
                            <Input
                                maxLength={6}
                                placeholder="รหัส OTP"
                                onChange={(item) => setOtpValue(item.target.value)}
                            />
                        </Form.Item>
                        {
                            showTime &&
                            <div className='flex justify-center items-center mb-5'>
                                <div className='mr-2 font-semibold'>
                                    รหัสจะหมดอายุ ภายในอีก
                                </div>
                                <Countdown
                                    className='font-semibold'
                                    value={deadLineTime}
                                    format="m:ss"
                                    valueStyle={{color: '#1890FF', fontSize: '14px'}}
                                    onFinish={() => {
                                        setDeadLineTime(0)
                                        setRequestOtpAgain(true)
                                    }}
                                />
                                <div className='ml-2 font-semibold'>
                                    นาที
                                </div>
                            </div>
                        }
                        <Button
                            className='bg-blue-button text-white font-semibold'
                            block
                            size='large'
                            htmlType='submit'
                            disabled={otpValue?.length !== 6}
                        >
                            ยืนยัน
                        </Button>
                        {requestOtpAgain && (
                            <div className='text-center mt-5'>
                                <Button
                                    type="link"
                                    size="large"
                                    onClick={sendRequestOTP}
                                >
                                    ขอรหัส OTP อีกครั้ง
                                </Button>
                            </div>
                        )}
                    </div>
                </Form>
            </ResultPage.Card>
        </>
    )
}

export default LogInOTP

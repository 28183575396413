import React from 'react'
import TabBoy from '../../component/TabBoy'
import TabCheckPayment from './TabCheckPayment'
import TabWaitForPay from './TabWaitForPay'
import TabPaid from './TabPaid'
import {Typography} from 'antd'
import {useParams} from 'react-router-dom'


const Payment: React.FC = () => {
    const params = useParams()

    const options = [
        {
            label: <span className='text-xs'>ตรวจสอบการชำระ</span>,
            value: 0
        },
        {
            label: <span className='text-xs'>รายการรอเรียกเก็บ</span>,
            value: 1
        },
        {
            label: <span className='text-xs'>เรียกเก็บแล้ว</span>,
            value: 2
        },
    ]

    const tabs = [
        <TabCheckPayment key={'TabCheckPayment'}/>,
        <TabWaitForPay key={'TabWaitForPay'}/>,
        <TabPaid key={'TabPaid'}/>,
    ]

    return (
        <TabBoy title='การชำระดอกเบี้ย' back tabs={tabs} options={options}>
            <Typography.Title
                className='border border-gray-300 p-2 rounded-lg m-3'
                level={5}
            >
                สัญญาขายฝาก : {params.contractId}
            </Typography.Title>
        </TabBoy>
    )
}

export default Payment

import React, {useState} from 'react'
import TabBoy from '../../component/TabBoy'
import TabOffer from './TabOffer'
import TabWaitForAuction from './TabWaitForAuction'
import TabResultAuction from './TabResultAuction'

const Auction: React.FC = () => {
    const [currentTab, setCurrentTab] = useState(0)

    const options = [
        {
            label: <span className='text-xs'>ยื่นข้อเสนอประมูล</span>,
            value: 0
        },
        {
            label: <span style={{fontSize: '10px'}}>อยู่ระหว่างการประมูล</span>,
            value: 1
        },
        {
            label: <span className='text-xs'>ผลการประมูล</span>,
            value: 2
        },
    ]

    const tabs = [
        <TabOffer key={'TabOffer'} setCurrentTab={setCurrentTab}/>,
        <TabWaitForAuction key={'TabWaitForAuction'}/>,
        <TabResultAuction key={'TabResultAuction'}/>,
    ]

    return (
        <TabBoy title='ประมูลดอกเบี้ย' back tabs={tabs} options={options} setTab={setCurrentTab} tab={currentTab}/>
    )
}

export default Auction

import React, {useEffect, useState} from 'react'
import {Image, List, ResultPage} from 'antd-mobile'
import {AddCircleOutline, ExclamationCircleOutline} from 'antd-mobile-icons'
import {UserOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router-dom'
import {Avatar, Button, Space, Spin, Typography} from 'antd'
import consigneXLogo from '../../iconBoy/logo.jpg'
import {liff} from '@line/liff'

const More: React.FC = () => {
    const [iconSrc, setIconSrc] = useState(consigneXLogo)
    const phoneNumber = localStorage.getItem('phoneNumber')
    const [name, setName] = useState(phoneNumber)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        setLoading(true)
        try {
            if (process.env.REACT_APP_ENV === 'production') {
                const profile = await liff.getProfile()
                setIconSrc(profile.pictureUrl || '')
                setName(profile.displayName)
            }
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    const handleLogout = () => {
        if (process.env.REACT_APP_ENV === 'production') {
            if (liff.isLoggedIn()) {
                liff.logout
            }
            localStorage.clear()
            navigate('/logIn')
        } else {
            localStorage.clear()
            navigate('/logIn')
        }
    }

    return (
        <Space direction='vertical' className='justify-between h-[75vh] w-full'>
            <div>
                <ResultPage.Card>
                    <Spin spinning={loading}>
                        <div className='mt-0 shadow-md rounded-2xl p-4 pb-2 text-center'>
                            <Avatar
                                className='mb-3'
                                size={64}
                                icon={
                                    <UserOutlined
                                        className='align-middle'
                                    />
                                }
                                src={iconSrc}
                            />
                            <Typography.Title level={4}>
                                {name}
                            </Typography.Title>
                        </div>
                    </Spin>
                </ResultPage.Card>
                <div className='text-xl font-bold ml-[1em] mb-0 mt-[16px]'>เพิ่มเติม</div>
                <List mode="card">
                    <List.Item
                        prefix={<AddCircleOutline className='text-blue-boy-secondary'/>}
                        onClick={() => navigate('sign-up')}
                    >
                        <span className='font-medium'>ลงทะเบียนเป็นนักลงทุน</span>
                    </List.Item>
                    <List.Item
                        prefix={<ExclamationCircleOutline className='text-blue-boy-secondary'/>}
                        onClick={() => navigate('contract')}
                    >
                        <span className='font-medium'>ติดต่อเรา</span>
                    </List.Item>
                </List>
                <div className='text-xl font-bold ml-[1em] mb-0 mt-[16px]'>Social Media</div>
                <List mode="card">
                    <List.Item
                        prefix={
                            <Image
                                src={'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png'}
                                height={30}
                                width={30}
                                className='rounded-[50%]'
                            />
                        }
                        onClick={() => {
                            window.location.href = 'https://www.facebook.com/Invest.CoachTae/'
                        }}
                    >
                        <span className='font-medium'>
                        ลงทุนขายฝาก-จำนอง by โค้ชเต๊ะ
                        </span>
                    </List.Item>
                </List>
            </div>
            <Button
                className='border-[2px] border-red-500 text-red-500 font-bold'
                block
                size='large'
                onClick={handleLogout}
            >
                ออกจากระบบ
            </Button>
        </Space>
    )
}

export default More

import React from 'react'
import {Button} from 'antd-mobile'
import {useNavigate} from 'react-router-dom'
import Lock from '../iconBoy/lock.svg'
import {liff} from '@line/liff'

const LogIn: React.FC = () => {
    const navigate = useNavigate()

    const handleLogin = () => {
        if (process.env.REACT_APP_ENV === 'production') {
            liff.login()
            if (liff.isLoggedIn()) {
                navigate('/LogInOTP')
            }
        } else {
            navigate('/LogInOTP')
        }
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <img src={Lock} alt='lock' width={'40%'} className='mb-5'/>
            <div className="text-lg font-bold">กรุณาเข้าสู่ระบบ ConsigneX</div>
            <div className="text-lg mb-5">เพื่อเริ่มติดตามสัญญาของท่าน</div>
            <Button
                className="w-80"
                color='primary'
                size='middle'
                onClick={handleLogin}
            >
                เข้าสู่ระบบ
            </Button>
        </div>
    )
}

export default LogIn
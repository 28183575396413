import React from 'react'
import {ResultPage} from 'antd-mobile'
import {Outlet} from 'react-router-dom'

const LayoutMore: React.FC = () => {
    return (
        <ResultPage
            title=''
            icon={
                <div className='text-xl'>
                    เข้าสู่ระบบ
                </div>
            }
            style={{
                '--background-color': '#205EBB',
                backgroundColor: 'white'
            }}
        >
            <Outlet/>
        </ResultPage>
    )
}

export default LayoutMore

import {Modal, Stepper} from 'antd-mobile'
import React, {useEffect, useState} from 'react'
import AssetComponent from '../../component/AssetComponent'
import {Button, Spin, Typography} from 'antd'
import auctionIcon from '../../iconBoy/auction-line.svg'
import {CheckCircleFill} from 'antd-mobile-icons'
import {mockData} from '../../constants/About'
import {axiosInvestor} from '../../config/axiosConfig'
import {useNavigate, useParams} from 'react-router-dom'

interface props {
    setCurrentTab: React.Dispatch<React.SetStateAction<number>>
}

type offerDetail = {
    src: string[]
    title: string
    estimatedAmount: number
    approvedAmount: number
    dueDate: string
}

const TabOffer: React.FC<props> = ({setCurrentTab}) => {
    const [currentTime, setCurrentTime] = useState(new Date())
    const [offerDetail, setOfferDetail] = useState<offerDetail>()
    const [loading, setLoading] = useState(false)
    const [offer, setOffer] = useState(9)
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date())
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        getOfferDetail()
    }, [])

    const getOfferDetail = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor(`/offerDetail/${params.asset}`)
            setOfferDetail(data)
        } catch (e) {
            // handle interceptor
            setOfferDetail({
                src: mockData.map((item) => item.src),
                title: 'บางกอก บูเลอวาร์ด รังสิต คลอง 4',
                estimatedAmount: 17000000,
                approvedAmount: 8500000,
                dueDate: '112',
            })
        }
        setLoading(false)
    }

    const auction = async () => {
        setLoading(true)
        try {
            await axiosInvestor.post(`/auction/${params.asset}`, {
                offer: offer
            })
            Modal.show({
                header: <CheckCircleFill className='text-7xl' color='#0DC75A'/>,
                title: 'ส่งข้อเสนอแล้ว',
                content:
                    <div className='p-3 px-12 text-blue-500'>
                        <Button
                            className='text-blue-500 border-blue-500'
                            block
                            onClick={() => {
                                setCurrentTab(1)
                                Modal.clear()
                            }}
                        >
                            ดูข้อมูล
                        </Button>
                    </div>
                ,
                closeOnMaskClick: true,
            })
        } catch (e) {
            //handle interceptor
            //for mock flow
            Modal.show({
                header: <CheckCircleFill className='text-7xl' color='#0DC75A'/>,
                title: 'ส่งข้อเสนอแล้ว',
                content:
                    <div className='p-3 px-12 text-blue-500'>
                        <Button
                            className='text-blue-500 border-blue-500'
                            block
                            onClick={() => {
                                setCurrentTab(1)
                                Modal.clear()
                            }}
                        >
                            ดูข้อมูล
                        </Button>
                    </div>
                ,
                closeOnMaskClick: true,
            })
            //for mock flow
        }
        setLoading(false)
    }

    return (
        <Spin spinning={loading}>
            <AssetComponent
                title={offerDetail?.title || '-'}
                estimatedAmount={offerDetail?.estimatedAmount || 0}
                approvedAmount={offerDetail?.approvedAmount || 0}
                images={offerDetail?.src || []}
                onclickImages={() => navigate(`/assets/${encodeURIComponent(params.asset || '')}/images`)}
            />
            <div className='p-3'>
                <Typography.Title level={5}>
                    ประมูลดอกเบี้ย
                </Typography.Title>
                <h1 className='text-red-600 mb-1'>
                    * ต่ำสุดที่ 9% สูงสุดไม่เกิน 15% ต่อปี
                </h1>
                <Stepper
                    style={{
                        width: '100%',
                        '--height': '50px',
                        '--input-font-size': '24px',
                    }}
                    onChange={(value) => setOffer(value)}
                    defaultValue={offer}
                    step={1}
                    min={9}
                    max={15}
                />
            </div>
            <div className='px-3 py-2 flex justify-end'>
                <div className='flex items-center text-center text-gray-500'>
                    <img
                        className='mx-1 fill-amber-100'
                        src={auctionIcon}
                        width={14}
                        height={14}
                        alt='icon'
                    />
                    สิ้นสุดการประมูลใน
                    <span
                        className='ml-1 text-red-500'>{currentTime.toLocaleTimeString()} {offerDetail?.dueDate}</span>
                </div>
            </div>
            <Button
                className='bg-blue-button text-white mt-24'
                block
                size='large'
                onClick={auction}
            >
                ยืนยัน
            </Button>
        </Spin>
    )
}

export default TabOffer

import React from 'react'
import Layout2 from '../component/Layout2'
import pic from '../iconBoy/develop.jpg'
import {Button} from 'antd-mobile'
import {useNavigate} from 'react-router-dom'

const Ai = () => {
    const navigate = useNavigate()

    return (
        <>
            <Layout2 title='Assess AI' back>
                <img
                    src={pic}
                    alt="Assess AI"
                    className="mt-32 mx-auto w-64 h-auto"
                />
                <div className="text-center mt-5 text-blue-700 font-bold text-xl">
                    Coming Soon!
                </div>
                <div className="mt-3 text-center text-lg font-bold">
                    อยู่ระหว่างการพัฒนาระบบ
                </div>
                <div className="mt-10 flex justify-center">
                    <Button
                        className="w-72"
                        color='primary'
                        onClick={() => navigate('/')}
                    >
                        กลับไปหน้าหลัก
                    </Button>
                </div>
            </Layout2>
        </>
    )
}

export default Ai

import React from 'react'
// @ts-ignore
import Map, {Layer, NavigationControl, Source} from 'react-map-gl'
import {fillLayer, lineLayer, MAP_STYLE_WITH_TOKEN, pointLayer} from './UtilMap'
import maplibreGL from 'maplibre-gl'

interface PreviewMapProps {
    mapRef: any
    focusFeatureMap?: any
    geoJson?: any
    setOpenDrawerNavtexDetail?: any
}

const PreviewMap: React.FC<PreviewMapProps> = ({geoJson, mapRef}) => {

    return (
        <>
            <Map
                reuseMaps={true}
                ref={mapRef}
                mapLib={maplibreGL as any}
                initialViewState={{
                    latitude: 12.5,
                    longitude: 100,
                    zoom: 7
                }}
                mapStyle={MAP_STYLE_WITH_TOKEN()}
                style={{width: '100%', height: '25rem'}}
                interactiveLayerIds={['sf-neighborhoods-fill', 'sf-neighborhoods-outline', 'sf-neighborhoods-circle']}
            >
                <NavigationControl position='bottom-right'/>
                {geoJson && <Source id='sf-neighborhoods' type='geojson' data={geoJson}>
                    <Layer {...fillLayer} />
                    <Layer {...lineLayer} />
                    <Layer {...pointLayer} />
                </Source>}
            </Map>
        </>
    )
}

export default PreviewMap
import React from 'react'
import {Typography} from 'antd'
import Layout2 from '../../component/Layout2'
import {Outlet} from 'react-router-dom'


const Documents: React.FC = () => {
    const contractId = localStorage.getItem('contractId')

    return (
        <Layout2 title='เอกสารสิทธิ์และอื่นๆ' back>
            <Typography.Title className='border-2 border-gray-200 p-2' level={5}>
                สัญญาขายฝาก : {contractId}
            </Typography.Title>
            {<Outlet/>}
        </Layout2>
    )
}

export default Documents

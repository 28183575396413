import {Spin, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import {Image, ImageViewer, NavBar} from 'antd-mobile'
import {axiosInvestor} from '../../config/axiosConfig'
import {useNavigate, useParams} from 'react-router-dom'


const Images: React.FC = () => {
    const [images, setImages] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        getImages()
    }, [])

    const getImages = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/asset/detail/image',
                {
                    params: {
                        id: decodeURIComponent(params.id || '')
                    }
                }
            )
            setImages(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    const showImages = (index: number) => {
        ImageViewer.Multi.show({
            images: images,
            defaultIndex: index,
        })
    }

    return (
        <>
            <NavBar
                backArrow
                onBack={() => navigate(-1)}
            >
                <Typography.Title
                    level={4}
                    className='text-center my-4'
                >
                    รูปภาพทั้งหมด
                </Typography.Title>
            </NavBar>
            <Spin spinning={loading}>
                <div className='flex flex-wrap min-h-full'>
                    {images.map((src, index) =>
                        <div
                            onClick={() => {
                                showImages(index)
                            }}
                            key={`div-${index}`}
                            className={`p-1 ${index % 3 === 0 ? '' : 'w-1/2'}`}
                        >
                            <Image
                                key={`image-${index}`}
                                src={src}
                                alt='image'
                            />
                        </div>
                    )}
                </div>
            </Spin>
        </>
    )
}

export default Images

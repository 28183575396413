import React, {useEffect, useState} from 'react'
import {Image, NavBar} from 'antd-mobile'
import {DownloadOutlined} from '@ant-design/icons'
import {axiosInvestor} from '../../config/axiosConfig'
import {useNavigate, useParams} from 'react-router-dom'
import {Spin} from 'antd'
import {Document, Page, pdfjs} from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

type file = {
    src: string
    title: string
}

const File: React.FC = () => {
    const [file, setFile] = useState<file>()
    const [loading, setLoading] = useState(false)
    const [width, setWidth] = useState(300)
    const [numPages, setNumPages] = useState<number>(0)
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        getFile()
        setWidth(window.screen.width)
    }, [])


    function onDocumentLoadSuccess({numPages}: { numPages: number }): void {
        setNumPages(numPages)
    }

    const getFile = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/contract/eachFileContractInvestor', {
                params: {
                    assetId: decodeURIComponent(params.contractId || ''),
                    documentId: decodeURIComponent(params.fileId || '')
                }
            })
            setFile(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    const downloadFile = async () => {
        try {
            const {data} = await axiosInvestor.get('/contract/downloadDocumentInvestor', {
                params: {
                    uid: decodeURIComponent(params.fileId || ''),
                    assetId: decodeURIComponent(params.contractId || ''),
                },
                responseType: 'blob',
            })
            const blob = new Blob([data])
            const dataUrl = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = dataUrl
            a.download = file?.title || 'unknown'
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(dataUrl)
            document.body.removeChild(a)
        } catch (e) {
            // handle interceptor
        }
    }

    return (
        <>
            <Spin spinning={loading}>
                <NavBar
                    className='bg-white'
                    onBack={() => navigate(-1)}
                    right={
                        <DownloadOutlined
                            className='text-xl'
                            onClick={downloadFile}
                        />
                    }
                >
                    {file?.title || ''}
                </NavBar>
            </Spin>
            <div className='border-t-4 border-gray-500'>
                {file && file.title.endsWith('.pdf') ? (
                    <Document
                        file={file?.src}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from({length: numPages}, (_, index) => (
                            <Page
                                key={`page-${index}`}
                                className='border-b-4 border-gray-500'
                                width={width}
                                pageNumber={index + 1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        ))}
                    </Document>
                ) : (
                    <Image
                        src={file?.src}
                        width="100%"
                    />
                )}
            </div>
        </>
    )
}

export default File

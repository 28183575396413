import React from 'react'
import userIcon from '../../iconBoy/user.svg'
import documentIcon from '../../iconBoy/document.svg'
import money2Icon from '../../iconBoy/money3.svg'
import {List} from 'antd'
import {RightOutline} from 'antd-mobile-icons'
import {useNavigate} from 'react-router-dom'


const ListDocuments: React.FC = () => {
    const navigate = useNavigate()
    const data = [
        {
            title: 'เอกสารส่วนบุคคล',
            icon: <img src={userIcon} alt='icon' width={20} height={20}/>,
            type: 'Personal',
        },
        {
            title: 'เอกสารหลักประกัน',
            icon: <img src={documentIcon} alt='icon' width={20} height={20}/>,
            type: 'Assurance',
        },
        {
            title: 'เอกสารทางการเงิน',
            icon: <img src={money2Icon} alt='icon' width={20} height={20}/>,
            type: 'Financial',
        },
    ]

    return (
        <List
            dataSource={data}
            renderItem={(item) => (
                <List.Item
                    onClick={() => navigate(`documents/${item.type}`)}
                    extra={<RightOutline/>}
                >
                    <span className='text-2xl bg-blue-boy-light p-3 mr-2 rounded-md'>
                        {item.icon}
                    </span>
                    <List.Item.Meta
                        title={`${item.title}`}
                    />
                </List.Item>
            )}
        />
    )
}

export default ListDocuments

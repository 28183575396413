import React, {useEffect, useState} from 'react'
import {Spin, Tag} from 'antd'
import {List} from 'antd-mobile'
import {useParams} from 'react-router-dom'
import {listPay} from '../../constants/interfaceAndType'
import {axiosInvestor} from '../../config/axiosConfig'
import {formatDate} from '../../util/helper'


const TabPaid: React.FC = () => {
    const [listPaid, setListPaid] = useState<listPay[]>([])
    const [loading, setLoading] = useState(false)
    const params = useParams()

    useEffect(() => {
        getListPaid()
    }, [])

    const getListPaid = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/contract/paymentDetailInvestor/status', {
                params: {
                    contractId: decodeURIComponent(params.contractId || ''),
                    status: ['Paid', 'PrePaid'].join(',')
                }
            })
            setListPaid(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    return (
        <div className='m-3'>
            <Spin spinning={loading}>
                <List>
                    {listPaid.map((item, index) =>
                        <List.Item
                            key={`listItem-${index}`}
                            description={`งวดที่ ${item.round} | ครบกำหนด ${formatDate(item.dueDate)}`}
                            extra={<span className='font-medium text-black'>THB {item.pay.toLocaleString()}</span>}
                        >
                            <div key={`span-${index}`} className='text-sm font-medium mb-2'>
                                {item.title} <br/>
                                <Tag color='green'>ชำระแล้ว</Tag>
                            </div>
                        </List.Item>
                    )}
                </List>
            </Spin>
        </div>
    )
}

export default TabPaid

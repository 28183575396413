// @ts-ignore
import {FillLayer, LayerProps, LineLayer, MapboxStyle} from 'react-map-gl'
import datavisMap from './dataviz-mapStyle.json'

export const MAP_STYLE_WITH_TOKEN = (): MapboxStyle => {
    const mapStyleJson = datavisMap
    mapStyleJson.sources.maptiler_planet.url = mapStyleJson.sources.maptiler_planet.url.replace(
        '${token}',
        process.env.REACT_APP_KEY_MAP_STYLE as string
    )
    mapStyleJson.glyphs = mapStyleJson.glyphs.replace(
        '${token}',
        process.env.REACT_APP_KEY_MAP_STYLE as string
    )
    return mapStyleJson as MapboxStyle
}

export const fillLayer: FillLayer = {
    id: 'sf-neighborhoods-fill',
    source: 'sf-neighborhoods',
    type: 'fill',
    paint: {
        'fill-outline-color': '#0040c8',
        'fill-color': '#fff',
        'fill-opacity': 0
    }
}

export const lineLayer: LineLayer = {
    id: 'sf-neighborhoods-outline',
    source: 'sf-neighborhoods',
    type: 'line',
    paint: {
        'line-width': 2,
        'line-color': '#0080ef'
    }
}

export const pointLayer: LayerProps = {
    id: 'sf-neighborhoods-circle',
    source: 'sf-neighborhoods',
    type: 'circle',
    paint: {
        'circle-color': '#0080ef',
        'circle-radius': 4,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
    }
}
import {RcFile} from 'antd/es/upload'

export const formatAssetType = (text: string) => {
    if (text === 'Apartment') {
        text = 'อะพาร์ตเมนต์'
    } else if (text === 'Commercial') {
        text = 'อาคารพาณิชย์'
    } else if (text === 'Condo') {
        text = 'คอนโด/ห้องชุด'
    } else if (text === 'DetachedHouse') {
        text = 'บ้านเดี่ยว'
    } else if (text === 'TownHome') {
        text = 'ทาวน์โฮม'
    } else if (text === 'TwinHouse') {
        text = 'บ้านแฝด'
    } else if (text === 'HomeOffice') {
        text = 'โฮมออฟฟิศ'
    } else if (text === 'Hotel') {
        text = 'โรงแรม'
    } else if (text === 'WarehouseFactory') {
        text = 'โกดัง/คลังสินค้า'
    } else if (text === 'VacantLand') {
        text = 'ที่ดินเปล่า'
    } else if (text === 'DevelopedLand') {
        text = 'ที่ดินพร้อมสิ่งปลูกสร้าง'
    } else if (text === 'Townhouse') {
        text = 'ทาวน์เฮ้าส์'
    } else if (text === 'PoolVilla') {
        text = 'พูลวิลล่า'
    } else if (text === 'Other') {
        text = 'อื่นๆ'
    }
    return text
}

export const formatDate = (date: string) => {
    const d = new Date(date)
    const day = d.getDate()
    const month = d.getMonth() + 1
    const year = d.getFullYear() + 543
    const formattedDay = day < 10 ? `0${day}` : `${day}`
    const formattedMonth = month < 10 ? `0${month}` : `${month}`
    return `${formattedDay}-${formattedMonth}-${year}`
}

export const formatTime = (date: string) => {
    const d = new Date(date)
    const hour = d.getHours()
    const minute = d.getMinutes()
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`
    return `${formattedHour}:${formattedMinute}`
}

export const formatContractStatus = (status: string) => {
    if (status === 'Active') {
        return 'อยู่ในสัญญา'
    } else if (status === 'Redeemed') {
        return 'ไถ่ถอนแล้ว'
    } else if (status === 'RunOut') {
        return 'หลุดสัญญา'
    }
}

export const formatColorContractStatus = (status: string) => {
    let color = ''
    if (status === 'Active') {
        color = '#14B724'
    } else if (status === 'Redeemed') {
        color = '#FFA500'
    } else if (status === 'RunOut') {
        color = '#FF0000'
    }
    return color
}

export const formatBackgroundColorContractStatus = (status: string) => {
    let color = ''
    if (status === 'Active') {
        color = '#D9F7BE'
    } else if (status === 'Redeemed') {
        color = '#FFE4CA'
    } else if (status === 'RunOut') {
        color = '#FFC0CB'
    }
    return color
}

export const investorInterestAssetTypeOptionNoContract = () => {
    return [
        {value: 'อะพาร์ตเมนต์', label: 'อะพาร์ตเมนต์'},
        {value: 'อาคารพาณิชย์', label: 'อาคารพาณิชย์'},
        {value: 'คอนโด/ห้องชุด', label: 'คอนโด/ห้องชุด'},
        {value: 'บ้านเดี่ยว', label: 'บ้านเดี่ยว'},
        {value: 'ทาวน์โฮม', label: 'ทาวน์โฮม'},
        {value: 'บ้านแฝด', label: 'บ้านแฝด'},
        {value: 'โฮมออฟฟิศ', label: 'โฮมออฟฟิศ'},
        {value: 'โรงแรม', label: 'โรงแรม'},
        {value: 'โกดัง/คลังสินค้า', label: 'โกดัง/คลังสินค้า'},
        {value: 'ที่ดินเปล่า', label: 'ที่ดินเปล่า'},
        {value: 'ที่ดินพร้อมสิ่งปลูกสร้าง', label: 'ที่ดินพร้อมสิ่งปลูกสร้าง'},
        {value: 'ทาวน์เฮ้าส์', label: 'ทาวน์เฮ้าส์'},
        {value: 'พูลวิลล่า', label: 'พูลวิลล่า'}
    ]
}

export const investorInterestLocationOptionNoContract = () => {
    return [
        {value: 'กรุงเทพ ฯ และปริมณฑล', label: 'กรุงเทพ ฯ และปริมณฑล'},
        {value: 'ภาคเหนือ', label: 'ภาคเหนือ'},
        {value: 'ภาคกลาง', label: 'ภาคกลาง'},
        {value: 'ภาคตะวันออกเฉียงเหนือ (ภาคอีสาน)', label: 'ภาคตะวันออกเฉียงเหนือ (ภาคอีสาน)'},
        {value: 'ภาคตะวันออก', label: 'ภาคตะวันออก'},
        {value: 'ภาคตะวันตก', label: 'ภาคตะวันตก'},
        {value: 'ภาคใต้', label: 'ภาคใต้'}
    ]
}

export const investorInvestAmountOptionNoContract = () => {
    return [
        {value: '500,000 - 1,000,000 บาท', label: '500,000 - 1,000,000 บาท'},
        {value: '1,000,000 - 3,000,000 บาท', label: '1,000,000 - 3,000,000 บาท'},
        {value: '3,000,000 - 5,000,000 บาท', label: '3,000,000 - 5,000,000 บาท'},
        {value: '5,000,000 บาทขึ้นไป', label: '5,000,000 บาทขึ้นไป'}
    ]
}

export const investorRefferalOptionNoContract = () => {
    return [
        {value: 'Facebook', label: 'Facebook'},
        {value: 'Tiktok', label: 'Tiktok'},
        {value: 'Youtube', label: 'Youtube'},
        {value: 'คอร์สสัมมนา', label: 'คอร์สสัมมนา'},
        {value: 'เว็บไซต์', label: 'เว็บไซต์'},
        {value: 'เพื่อนแนะนำ', label: 'เพื่อนแนะนำ'}
    ]
}

export const formatNumber = (number: number) => {
    return new Intl.NumberFormat('th-TH', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(number)
}

export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })
import React, {useEffect, useState} from 'react'
import {Button, Form, Spin, Tag} from 'antd'
import {List, Modal as ModalMobile, Tabs, TextArea} from 'antd-mobile'
import {FieldTimeOutlined} from '@ant-design/icons'
import {CheckCircleFill, CheckShieldOutline, CloseCircleFill, CloseShieldOutline} from 'antd-mobile-icons'
import ModalInfoPayment from './ModalInfoPayment'
import {axiosInvestor} from '../../config/axiosConfig'
import {useParams} from 'react-router-dom'
import {paymentDetail} from '../../constants/interfaceAndType'


const TabCheckPayment: React.FC = () => {
    const [formReason] = Form.useForm()
    const [paymentDetail, setPaymentDetail] = useState<paymentDetail[]>([])
    const [dataPaymentDetail, setDataPaymentDetail] = useState<paymentDetail>()
    const [activeKey, setActiveKey] = useState('waitForApprove')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [footer, setFooter] = useState(false)
    const [loading, setLoading] = useState(false)
    const params = useParams()

    useEffect(() => {
        if (activeKey === 'waitForApprove') {
            getPaymentDetailWait()
        } else {
            getPaymentDetailApprove()
        }
    }, [activeKey])

    const getPaymentDetailWait = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/contract/paymentDetailInvestor/checkStatus', {
                params: {
                    contractId: decodeURIComponent(params.contractId || ''),
                    status: 'Pending'
                }
            })
            setPaymentDetail(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    const getPaymentDetailApprove = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInvestor.get('/contract/paymentDetailInvestor/checkStatus', {
                params: {
                    contractId: decodeURIComponent(params.contractId || ''),
                    status: ['Paid', 'Rejected'].join(',')
                }
            })
            setPaymentDetail(data)
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }
    
    const handleSubmit = async (value: any) => {
        setLoading(true)
        try {
            await axiosInvestor.put('/contract/managePayment', {
                contractId: dataPaymentDetail?.contractId,
                round: dataPaymentDetail?.round,
                manage: 'Reject',
                rejectReason: value.rejectReason
            })
            ModalMobile.clear()
            ModalMobile.show({
                header: <CloseCircleFill className='text-7xl' color='red'/>,
                title: 'ปฏิเสธสำเร็จ',
                content:
                        <div className='p-3 px-12 text-blue-500'>
                            <Button
                                className='text-blue-500 border-blue-500'

                                block
                                onClick={() => {
                                    ModalMobile.clear()
                                    setActiveKey('approve')
                                }}
                            >
                                ดูข้อมูล
                            </Button>
                        </div>
                ,
                closeOnMaskClick: true,
            })
        } catch (e) {
            // handle interceptor
        }
        setLoading(false)
    }

    return (
        <div className='p-3'>
            <ModalInfoPayment
                dataPayment={dataPaymentDetail}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                footer={footer}
            >
                <Button
                    className='mt-3'
                    style={{borderColor: '#22c55e', color: '#22c55e'}}
                    block
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)
                        try {
                            await axiosInvestor.put('/contract/managePayment', {
                                contractId: dataPaymentDetail?.contractId,
                                round: dataPaymentDetail?.round,
                                manage: 'Approved'
                            })
                            setIsModalOpen(false)
                            ModalMobile.show({
                                header: <CheckCircleFill className='text-7xl' color='#0DC75A'/>,
                                title: 'อนุมัติเรียบร้อยแล้ว',
                                content:
                                    <div className='p-3 px-12 text-blue-500'>
                                        <Button
                                            className='text-blue-500 border-blue-500'
                                            block
                                            onClick={() => {
                                                ModalMobile.clear()
                                                setActiveKey('approve')
                                            }}
                                        >
                                            ดูข้อมูล
                                        </Button>
                                    </div>
                                ,
                                closeOnMaskClick: true,
                            })
                        } catch (e) {
                            // handle interceptor
                        }
                        setLoading(false)
                    }}
                >
                    อนุมัติ
                </Button>
                <Button
                    style={{borderColor: 'red', color: 'red'}}
                    className='mt-3'
                    block
                    onClick={async () => {
                        setIsModalOpen(false)
                        ModalMobile.show({
                            title: 'เหตุผลในการปฏิเสธ',
                            content:
                                <>
                                    <Form onFinish={handleSubmit} form={formReason}>
                                        <Form.Item
                                            name={'rejectReason'}
                                            rules={[{required: true, message: 'กรุณากรอกเหตุผลที่ปฏิเสธ'}]}
                                        >
                                            <TextArea
                                                className='border border-solid border-gray-300 rounded-lg p-4 mb-5'
                                                placeholder='กรอกเหตุผล...'
                                                autoSize={{minRows: 3, maxRows: 10}}
                                            />
                                        </Form.Item>
                                        <Button
                                            key='cancel'
                                            style={{width: '50%', marginRight: '5px'}}
                                            onClick={() => {ModalMobile.clear()}}
                                        >
                                            ปิด
                                        </Button>
                                        <Button
                                            key='submit'
                                            htmlType={'submit'}
                                            type="primary"
                                            style={{width: '46%', backgroundColor: '#1777ff', color: 'white'}}
                                        >
                                            ส่ง
                                        </Button>
                                    </Form>
                                    
                                </>
                            ,
                            closeOnMaskClick: true,
                        })
                    }}
                    loading={loading}
                >
                    ปฏิเสธ
                </Button>
            </ModalInfoPayment>
            <Spin spinning={loading}>
                <Tabs
                    activeKey={activeKey}
                    className='mt-2'
                    onChange={(key) => setActiveKey(key)}
                >
                    <Tabs.Tab title='ตรวจสอบหลักฐาน' key='waitForApprove'>
                        <List>
                            {paymentDetail?.map((item, index) =>
                                <List.Item
                                    onClick={() => {
                                        setFooter(true)
                                        setIsModalOpen(true)
                                        setDataPaymentDetail(item)
                                    }}
                                    arrow={false}
                                    prefix={<FieldTimeOutlined className='text-2xl text-blue-boy'/>}
                                    description={`ชำระดอกเบี้ยงวดที่ ${item.round}`}
                                    extra={<Tag color='orange'>รอการอนุมัติ</Tag>}
                                    key={`list-item-payWait${index}`}
                                >
                                    <span
                                        key={`span-payWait${index}`}
                                        className="text-sm font-medium"
                                    >
                                        {item.month}
                                    </span>
                                </List.Item>
                            )}
                        </List>
                    </Tabs.Tab>
                    <Tabs.Tab title='ผลการตรวจสอบ' key='approve'>
                        <List>
                            {paymentDetail?.map((item, index) =>
                                <List.Item
                                    onClick={() => {
                                        setFooter(false)
                                        setIsModalOpen(true)
                                        setDataPaymentDetail(item)
                                    }}
                                    prefix={item.status === 'Paid' ? <CheckShieldOutline className='text-2xl text-green-500'/> : <CloseShieldOutline className='text-2xl text-red-500'/>}
                                    description={`ชำระดอกเบี้ยงวดที่ ${item.round}`}
                                    extra={<Tag color={item.status === 'Paid' ? 'green' : 'red'}>{item.status === 'Paid' ? 'อนุมัติแล้ว' : 'ถูกปฏิเสธ'}</Tag>}
                                    key={`list-item-payApprove${index}`}
                                >
                                    <span
                                        key={`span-payApprove${index}`}
                                        className="text-sm font-medium"
                                    >
                                        {item.month}
                                    </span>
                                </List.Item>
                            )}
                        </List>
                    </Tabs.Tab>
                </Tabs>
            </Spin>
        </div>
    )
}

export default TabCheckPayment

import React from 'react'
import {Card as CardD, Col, Divider, Row} from 'antd'
import {Link} from 'react-router-dom'
import {AutoCenter, Image} from 'antd-mobile'
import estateIcon from '../iconBoy/estate.svg'
import money2Green from '../iconBoy/money2Green.svg'
import money2Red from '../iconBoy/money2Red.svg'
import noAsset from '../iconBoy/noAsset.svg'
import calendar from '../iconBoy/calendar.svg'
import {asset} from '../constants/interfaceAndType'
import {formatDate} from '../util/helper'
import consigneXLogo from '../iconBoy/logo.jpg'

interface props {
    title: string
    data: asset[]
    right?: boolean
    onClick?: (
        item: asset,
        index: number
    ) => void
}

const ListAsset: React.FC<props> = ({title, data, right, onClick}) => {
    return (
        <CardD
            bordered={false}
            title={title}
            extra={right ? <Link to="/portfolio">ดูทั้งหมด</Link> : null}
        >
            {
                data.map((item, index) => (
                    <Row
                        key={`row-${item.title}-${index}`}
                        className='mb-1'
                        onClick={onClick ? () => onClick(item, index) : () => {
                        }}
                    >
                        <Col key={`col1-${item.title}-${index}`} span={8}>
                            <Image
                                src={item?.src !== null ? item.src : consigneXLogo}
                                height={110}
                                fit='cover'
                                style={{borderRadius: '5px'}}
                            />
                        </Col>
                        <Col
                            key={`col2-${item.title}-${index}`}
                            className='p-3 pt-0'
                            span={16}
                        >
                            <h1 className='font-semibold'>{item.contractId} : {item.title}</h1>
                            <div className='flex items-center text-xs mt-1'>
                                <img
                                    className='mx-1'
                                    src={estateIcon}
                                    width={12}
                                    height={12}
                                    alt='icon'
                                />
                                <span>ประเภทธุรกรรม : <b>{item.transactionType}</b></span>
                            </div>
                            <div className='flex items-center text-xs mt-1'>
                                <img
                                    className='mx-1'
                                    src={money2Green}
                                    width={12}
                                    height={12}
                                    alt='icon'
                                />
                                <span>ดอกเบี้ยที่ได้รับ : <b>{item.transferAmount.toLocaleString()} บาท</b></span>
                            </div>
                            <div className='flex items-center text-xs mt-1'>
                                <img
                                    className='mx-1'
                                    src={money2Red}
                                    width={12}
                                    height={12}
                                    alt='icon'
                                />
                                <span>ดอกเบี้ยค้างรับ : <b>{item.accruedInterest?.toLocaleString()} บาท</b></span>
                            </div>
                            <div className='flex items-center text-xs mt-1'>
                                <img
                                    className='mx-1'
                                    src={calendar}
                                    width={12}
                                    height={12}
                                    alt='icon'
                                />
                                <span>ชำระครั้งถัดไป : <b>{formatDate(item.nextDate)}</b></span>
                            </div>
                        </Col>
                        <Divider style={{margin: '1em 0'}}/>
                    </Row>
                ))
            }
            {data.length === 0 &&
                <div className='text-center text-xl font-medium'>
                    <AutoCenter className='my-3'>
                        <img
                            src={noAsset}
                            alt='icon'
                        />
                    </AutoCenter>
                    คุณยังไม่มีทรัพย์ที่ทำธุรกรรม
                </div>
            }
        </CardD>
    )
}

export default ListAsset
